<template>
  <div class="main-layout">
    <div class="main-layout__wrapper">
      <AppHeader class="container" />
      <slot />
      <AppFooter class="container" />
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>

</script>

<style lang="scss">
html,
body {
  overflow-x: clip;
}

.main-layout {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__wrapper {
    // max-width: 1200px;
    width: 100%;
  }
}
</style>